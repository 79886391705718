<template>
    <div>
        <div class="fixed inset-0 z-30 bg-red-200" style="opacity: 0.5" aria-hidden="true"></div>
            <!--   show modal   -->
        <div class="z-40 inset-0 fixed flex justify-center items-center overflow-y-auto">
          <div class=" flex flex-col w-16/12 sm:w-5/6 lg:w-1/2 max-w-md mx-auto rounded-lg border border-gray-300 shadow-xl">
            <div class=" flex flex-row justify-between items-center px-3 py-3 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg">
              <p class="font-semibold text-sm text-gray-800">Voir les informations d'une question</p>
              <svg @click="closeShowQuestion" class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </div>
            <div class="flex flex-col px-6 py-5 bg-gray-50">

              <div :class="isLoading ? 'block' : 'hidden'" class="flex items-center justify-center h-full w-full">
                <!--<div class="fulfilling-square-spinner-show">
                  <div class="spinner-inner-show"></div>
                </div>-->
                <div class="loading">
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                </div>
              </div>

              <div :class="isLoading ? 'hidden' : 'block'" class=" flex flex-col sm:flex-row items-center text-center text-sm p-3 sm:space-x-5">   
                <div class="w-full text-left">
                  <div class="flex justify-around">                  
                    <div class="border rounded-lg mb-5 bg-white shadow-sm w-2/5">
                      <div class="flex flex-row border-b px-2 py-1">
                        <span class="mr-2">Source</span>
                      </div>
                      
                      <div class="p-2 br">
                            {{ source }}
                      </div>
                      
                  </div>
                  <div class="border rounded-lg mb-5 bg-white shadow-sm w-2/5">
                        <div class="flex flex-row border-b px-2 py-1">
                          <span class="mr-2">Année</span>
                        </div>
                        
                        <div  class="p-2 br">
                          {{ mois[(new Date(question.year)).getMonth()] +" "+(new Date(question.year)).getFullYear() }}
                        </div>
                       
                    </div>
                  </div>

                  <div class="border rounded-lg mb-5 bg-white shadow-sm">
                    <div v-if="selectedQuestion.type != 'clinicalcas'" class="flex flex-row border-b px-2 py-1">
                      <span class="mr-2">Question</span>
                      <svg v-if="question.Verified === '1'" class="my-auto" width="14" height="14" viewBox="0 0 24 24"><path fill="#f87171" d="M23.873 9.81c.087-.251.127-.509.127-.764 0-.77-.38-1.514-1.055-1.981-2.153-1.492-1.868-1.117-2.679-3.543-.34-1.013-1.319-1.697-2.424-1.697h-.007c-2.653.009-2.193.151-4.334-1.354-.446-.314-.974-.471-1.501-.471s-1.055.157-1.502.471c-2.156 1.515-1.686 1.362-4.334 1.353h-.007c-1.104 0-2.084.685-2.422 1.697-.812 2.432-.534 2.056-2.678 3.544-.677.469-1.057 1.212-1.057 1.983 0 .254.042.511.127.762.831 2.428.829 1.962 0 4.38-.085.251-.127.507-.127.762 0 .77.38 1.514 1.057 1.983 2.146 1.49 1.868 1.113 2.679 3.543.338 1.013 1.317 1.697 2.422 1.697h.007c2.653-.009 2.193-.152 4.334 1.353.446.314.974.472 1.501.472s1.055-.158 1.502-.471c2.141-1.504 1.679-1.362 4.334-1.353h.007c1.104 0 2.084-.685 2.424-1.697.811-2.427.525-2.052 2.679-3.543.674-.469 1.054-1.213 1.054-1.983 0-.254-.04-.512-.127-.763-.831-2.428-.827-1.963 0-4.38zm-13.373 6.94l-4.5-4.364 1.858-1.857 2.642 2.505 5.642-5.783 1.858 1.857-7.5 7.642z"/></svg>
                    </div>
                    <div v-else  class="flex flex-row border-b px-2 py-1">
                      
                      <span class="mr-2">Cas clinique</span>
                      <svg v-if="question.Verified === '1'" class="my-auto" width="14" height="14" viewBox="0 0 24 24"><path fill="#f87171" d="M23.873 9.81c.087-.251.127-.509.127-.764 0-.77-.38-1.514-1.055-1.981-2.153-1.492-1.868-1.117-2.679-3.543-.34-1.013-1.319-1.697-2.424-1.697h-.007c-2.653.009-2.193.151-4.334-1.354-.446-.314-.974-.471-1.501-.471s-1.055.157-1.502.471c-2.156 1.515-1.686 1.362-4.334 1.353h-.007c-1.104 0-2.084.685-2.422 1.697-.812 2.432-.534 2.056-2.678 3.544-.677.469-1.057 1.212-1.057 1.983 0 .254.042.511.127.762.831 2.428.829 1.962 0 4.38-.085.251-.127.507-.127.762 0 .77.38 1.514 1.057 1.983 2.146 1.49 1.868 1.113 2.679 3.543.338 1.013 1.317 1.697 2.422 1.697h.007c2.653-.009 2.193-.152 4.334 1.353.446.314.974.472 1.501.472s1.055-.158 1.502-.471c2.141-1.504 1.679-1.362 4.334-1.353h.007c1.104 0 2.084-.685 2.424-1.697.811-2.427.525-2.052 2.679-3.543.674-.469 1.054-1.213 1.054-1.983 0-.254-.04-.512-.127-.763-.831-2.428-.827-1.963 0-4.38zm-13.373 6.94l-4.5-4.364 1.858-1.857 2.642 2.505 5.642-5.783 1.858 1.857-7.5 7.642z"/></svg>
                    
                    </div>
                    <div class="p-2 br">
                      {{question.question}}
                    </div>
                  </div>

                  <div v-if="selectedQuestion.type == 'qroc'" class="border rounded-lg bg-white shadow-sm">
                    <div class="border-b px-2 py-1">
                      Réponse type 
                    </div>
                    <div class="p-2 br">
                      {{question.response}}
                    </div>
                  </div>


                  <div v-if="selectedQuestion.type== 'qcm' || selectedQuestion.type== 'qcs'" class="border rounded-lg bg-white shadow-sm">
                    <div class="px-2 py-1">
                      Suppositions 
                    </div>
                    <div>
                      <div class="flex justify-between items-center border-t px-2 py-1" v-for="proposition in question.propositions" :key="proposition.id">
                        <div>
                          {{proposition.proposal}}
                        </div>
                        <div v-if="proposition.state == '1' " class="h-2 w-2 bg-green-400 rounded-full"></div>
                        <div v-else class="h-2 w-2 bg-red-400 rounded-full"></div>
                      </div>
                    </div>
                  </div>


                  <div v-if="selectedQuestion.type == 'clinicalcas'" >
                    <el-collapse  v-model="activeNames" @change="handleChange" class="bg-white border rounded-lg shadow-sm">
                      <el-collapse-item class="px-4"  v-for="(ques,index) in question.questions" :key="ques.id" :title="'Question ' + (index+1)"  >
                        <div>
                        
                          <div class="border rounded-lg mb-3">
                            <div class="border-b px-2 py-1">
                              Question 
                            </div>
                            <div class="p-2 br">
                              {{ ques.question }}
                            </div>
                          </div>

                          <div v-if="ques.type== 'qcm' || ques.type== 'qcs'" class="border rounded-lg">
                            <div class="px-2 py-1">
                              Suppositions 
                            </div>
                            <div>
                              <div class="flex justify-between items-center border-t py-1 px-2" v-for="proposal in ques.propositions" :key="proposal.id">
                                <div>
                                  {{proposal.proposal}}
                                </div>
                                <div v-if="proposal.state == '1' " class="h-2 w-2 bg-green-400 rounded-full"></div>
                                <div v-else class="h-2 w-2 bg-red-400 rounded-full"></div>
                              </div>
                            </div>
                          </div>

                          <div v-if="ques.type== 'qroc'" class="border rounded-lg">
                            <div class="border-b px-2 py-1">
                              Réponse type 
                            </div>
                            <div class="p-2 br">
                              {{ques.response}}
                            </div>
                          </div>

                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </div>



                </div>
              </div>
            </div>
            <div
              class=" flex flex-row items-center justify-between px-6 py-3 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg "
            >
              <button
                @click="closeShowQuestion"
                class="font-semibold text-gray-600 text-sm"
              >
                Annuler
              </button>
              <div></div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import axiosIns from '../../../plugins/axios';

export default {

  data () {
    return {
      isLoading : false,
      question : [],
      mois : ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
      source : null,
    }
  },

  mounted () {
    if(this.selectedQuestion != null){
      this.isLoading = true
      if(this.selectedQuestion.type != 'clinicalcas'){
        axiosIns
          .get("/getquestion/"+this.selectedQuestion.id)
          .then(({ data }) => {
            this.question = data.result;
            axiosIns
              .get("/source/" + this.question.source_id)
              .then(({ data }) => {
                this.source = data.message.title;
                this.isLoading = false;
              })
              .catch(() => {
                this.isLoading = false;
              });
            
          })
          .catch(() => {
            this.isLoading = false;
          });
      }else{
        axiosIns
          .get("/getclinicalcas/"+this.selectedQuestion.id)
          .then(({ data }) => {
            this.question = data.clinicalcases;
            axiosIns
              .get("/source/" + this.question.source_id)
              .then(({ data }) => {
                this.source = data.message.title;
                this.isLoading = false;
              })
              .catch(() => {
                this.isLoading = false;
              });
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
      
    }
  },

  props:{
    closeShowQuestion:Function,
    selectedQuestion: Object
  },

  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
  }
 
};
</script>

<style>
.fulfilling-square-spinner-show,
.fulfilling-square-spinner-show * {
  box-sizing: border-box;
}

.fulfilling-square-spinner-show {
  height: 70px;
  width: 70px;
  position: relative;
  border: 4px solid #f87171;;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner-show .spinner-inner-show {
  vertical-align: top;
  display: inline-block;
  background-color: #f87171;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
</style>